import React from 'react';
import { Menu,Layout } from 'antd';
import { Link } from 'react-router-dom';
import logo from "../file.png";
import '../App.css'; // Custom CSS for the navbar
import { HomeOutlined, InfoCircleOutlined, PhoneOutlined,GiftFilled ,SolutionOutlined,ProjectOutlined} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;
const items = [
    // {
    //   key: 'logo',
    //   label: (
    //     <div className="logo">
    //       {/* Replace with your logo image */}
    //       <img src={logo} alt="Logo" />
    //       <Link to="/"></Link>
    //     </div>
    //   ),
    // },
    {
      key: '1',
      icon: <HomeOutlined />,
      label: <Link to="/">Home</Link>,
    },
    {
      key: '2',
      icon: <InfoCircleOutlined />,
      label: <Link to="/about">About</Link>,
    },
    {
      key: '3',
      icon: <PhoneOutlined />,
      label: <Link to="/contact">Contact</Link>,
    },
    {
      key: '4',
      icon: <SolutionOutlined />,
      label: <Link to="/whatwedo">What We Do</Link>,
    },
  
    {
      key: '5',
      icon:<GiftFilled />,
      label: <Link to="/donations">Donations</Link>,
    },
  ];
  
const Navbar = () => {
return(
    <div theme="dark" className="navbar"> 
<Layout>
    <Header>
         <div className="logo" >
        <img src={logo} alt="Logo" style={{width:85,height:105}} />
      </div>
        
           <Menu
              
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={['1']}
              items={items}  // Use the items array here
            /> 
    </Header>
    
</Layout>
      
    </div>
);
     
    
 
};

export default Navbar;
