import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Donations from './Pages/Donations';
import HowWeDo from './Pages/HowWeDo';
import WhatweDo from './Pages/WhatweDo';

const { Header, Content, Footer: AntFooter } = Layout;

const App = () => {
  return (
    <Router>
      <Layout>
       
          <Navbar />
    
        <Content style={{ padding: '0% 1%', marginTop: 64 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/donations" element={<Donations />} />
            <Route path="/howwedo" element={<HowWeDo />} />
            <Route path="/whatwedo" element={<WhatweDo />} />
          </Routes>
          <div className="site-layout-content" style={{ minHeight: '80vh' }}>
          
        </div>
        </Content>
        <AntFooter style={{ textAlign: 'left', backgroundColor:"#001529" }}>
          <Footer />
        </AntFooter>
      </Layout>
    </Router>
  );
};

export default App;
