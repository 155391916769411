import React from "react";
import { Carousel, Button, Card, Flex, Typography } from "antd";
import { Link } from "react-router-dom";
import "../App.css"; // Custom CSS for the navbar
import logo from "../logo512.png";
import logo2 from "../High-Resolution-Color-Logo.png";
import image1 from "../Images/10497167_879812985374904_1830038690219098855_o.jpg";
import image2 from "../Images/city traffic.jpg";
import image3 from "../Images/ayesha sana.jpg";
import image4 from "../Images/paidal.jpg";
import image5 from "../Images/internships.jpg";
import image6 from "../Images/greeen med.jpg";
import image7 from "../Images/green med.jpg";
import image8 from "../Images/greenn med.jpg";
import ceo from "../Ashba.jpg";

const contentStyle = {
  margin: 0,
  height: "560px",
  width: "100%",
  color: "#fff",
  align: "center",
  background: "#364d79",
};

const cardStyle = {
  width: "100%",
  height: "500px",
};
const imgStyle = {
  display: "block",
  width: 300,
};
const Home = () => {
  return (
    <div>
      <Carousel
        theme="dark"
        style={{ width: "100%" }}
        autoplay
        speed={500}
        effect="scrollx"
        dotHeight={14}
      >
        <div>
          <img src={image1} style={contentStyle} alt="Logo" />
        </div>
        <div>
          <img src={image2} style={contentStyle} alt="Logo" />
        </div>
        <div>
          <img src={image3} style={contentStyle} alt="Logo" />
        </div>
        <div>
          <img src={image4} style={contentStyle} alt="Logo" />
        </div>
        <div>
          <img src={image5} style={contentStyle} alt="Logo" />
        </div>
        <div>
          <img src={image6} style={contentStyle} alt="Logo" />
        </div>
        <div>
          <img src={image7} style={contentStyle} alt="Logo" />
        </div>
        <div>
          <img src={image8} style={contentStyle} alt="Logo" />
        </div>
      </Carousel>
      <div style={{ padding: "50px 0px 0px 0px" }}>
        <Card
          hoverable
          style={cardStyle}
          styles={{
            body: {
              padding: 0,
              overflow: "hidden",
            },
          }}
        >
          <Flex gap="middle" style={{ padding: "10px 0px 0px 0px" }}>
            <Flex
              vertical
              align="flex-start"
              gap="middle"
              style={{
                padding:"20px 0px 0px 20px",
              }}
            >
              <Typography.Title type="success" level={1} className="customfont">
                Invest Today for your child's Tomorrow
              </Typography.Title>
              <Typography.Paragraph>
                “Almighty has chosen me to support learning disabilities for
                which I am thankful and blessed. Not a single day goes
                purposeless. I feel contented that my dedication and struggle
                has caused change in the lives of many. Alhumdulillah! Now my
                concern is: what I have started has come to a level where
                everyone has setup their minds that a facility is available for
                LD children & Individuals but no one is seeing beyond
                that.Unfortunately, in our country there are training and
                vocational centers available but no IN dialone has vision what
                after this? Who will employ and what are the opportunities? My
                greatest fear is: if the mechanism came to halt? if agreed
                partner organizations reached to their saturation point then who
                will let LD individuals attain their right of employment and
                decent life? I am inviting all the companies to synergize with
                us. I request to the people to invest in these children so they
                can also pay back to”
              </Typography.Paragraph>
              {/* <Typography.Text level={3}>
                FRESH has been changing lives since 2002. Its vision has evolved through different phases. The struggle started off with one school having remedial program.Unlike mainstream schools, this remedial school was a pilot project. It had no business proposal, no agenda for profits and future plans.Founder and CEO, Ashba Kamran brought this program from US to Pakistan to give a try to educate her son and three other children like him. Problem was not only their learning difficulty but society's failure to understand them and provide them opportunities to flourish.This begining was later coupled by FRESH's job placement program and trainings.One school expanded to three more schools.Then job placement program became nationwide program.In ten years of hard work, FRESH has faced many hard times in convincing people, parents, and companies. As a result it had launched its awareness campaign in 2012 by the name of J Pakistan Association for Difficulties in Learning.Today people know about Paidal, They talk about learning difficulties but unfortunately fear of the social stigma has captivated their minds. FRESH, aims to free all minds of this slavish thought, wake-up everyone about the learning problems and focus towards their solution.
                    </Typography.Text> */}

              <Link to="/donations">
                <Button
                  style={{
                    alignContent: "start",
                    backgroundColor: "orange",
                    margin: 20,
                  }}
                  type="primary"
                >
                  Donate us
                </Button>
              </Link>
            </Flex>

            <img
              alt="avatar"
              src={ceo}
              // style={imgStyle}
              style={{
                ...imgStyle, // Existing styles
                borderRadius: "10%", // Make the image round
                width: "100%", // Optional: Ensure it scales well
                // maxWidth: "100%", // Optional: Set a max width if needed
                height: "auto", // Maintain aspect ratio/
              }}
            />
          </Flex>
        </Card>
        {/* <Typography.Title level={2} align="center" style={{ paddingTop: 20 }}>
          “antd is an enterprise-class UI design language and React UI library.”
        </Typography.Title> */}
      </div>
    </div>
  );
};

// From CEO
// Almighty has chosen me to support learning disabilities for which I am thankful and blessed. Not a single day goes purposeless. I feel contented that my dedication and struggle has caused change in the lives of many. Alhumdulillah!
// Now my concern is: what I have started has come to a level where everyone has setup their minds that a facility is available for LD children & Individuals but no one is seeing beyond that.Unfortunately, in our country there are training and vocational centers
// available but no IN dialone has vision what after this? Who will employ and what are the opportunities?
// My greatest fear is: if the mechanism came to halt? if agreed partner organizations reached to their saturation point then who will let LD individuals attain their right of employment and decent life?
// I am inviting all the companies to synergize with us. I request to the people to invest in these children so they can also pay back to
// What is "Special Learning Needs"?
// It is special educational requirements of those with learning difficulties, emotional or behavioral problems, or physical disabilities a. Reading Problems
// Several problems constitutes to reading deficiency such as lacking phonics and comprehension, visual processing & learning disabilities.b.
// Writing Problems
// Writing problems are mostly coupled by attention problem resulting in distraction, fatigue, inconsistent writing patterns, careless mistakes, poor compositions.
// C. Memory Problem

// The two streams (remedial & main) go hand in hand. This creates balance in society. Not everyone
// can be employer and
// neither all can be
// employees.
// LUMS, LSE, IBA, BNU, Indus Valley etc are
// giving us leaders but without a
// support team they will worth nothing!
// I request to
// Institutions to open seats for children with Learning Difficulties. They may not be graduates but your acceptance, grooming, support will do wonders. They will gain confidence, knowledge and will come out of stigma that they can't achieve without matriculation.
// I request to policy makers to amend old and make new policies. Give equal consideration to LD persons for education, employment and rights as citizens.
// www.
// HAR
// grammar and punctuation.
// Why Remedial?
// Remedial education
// helps students who are lagging behind up to the level of achievement realized by their peers. Strategy is to keep group strength of either 3 or 5 students depending on the level of difficulty.
// What happens When ADD & ADHD Attention Deficit Hyperactive disorder goes neglected?
// Likely to dropout at an early level of education.
// Unable to organize their lives, jobs, finances.
// Can develop drugs problem.
// Leads to unhappy incidents.

export default Home;
