import React from "react";
import {
  XOutlined,
  InstagramFilled,
  FacebookOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import logo from "../High-Resolution-Color-Logo.png";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
const contentStyle = {
  borderRadius: "10%", // Make the image round
  width: "100%", // Optional: Ensure it scales well
  maxWidth: "220px", // Optional: Set a max width if needed
  height: "220px", // Maintain aspect ratio
};
const fonts = {
  color: "orange", // Change to your desired color
  fontSize: "18px", // Change to your desired size
};
const fontsText = {
  color: "orange", // Change to your desired color
  fontSize: "15px", // Change to your desired size
};
// import * as utils from '../utils';

// const items = [
//     {
//       key: '1',
//       label: 'UserName',
//       children: 'Zhou Maomao',

//     },
//     {
//       key: '2',
//       label: 'Telephone',
//       children: '1810000000',
//     },
//     {
//       key: '3',
//       label: 'Live',
//       children: 'Hangzhou, Zhejiang',
//     },
//     {
//       key: '4',
//       label: 'Remark',
//       children: 'empty',
//     },
//     {
//       key: '5',
//       label: 'Address',
//       children: 'No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China',
//     },
//   ];
const Footer = () => {
  return (
    //     <div>
    //     <Flex justify="space-between" verticle>
    // <Flex verticale>
    //   <h1>First</h1>
    //   <h1>First</h1>
    //   <h1>First</h1>
    //   <h1>First</h1>
    //   <h1>First</h1>
    // </Flex>
    // <Flex verticale>
    //   <h1>First</h1>
    //   <h1>First</h1>
    //   <h1>First</h1>
    //   <h1>First</h1>
    //   <h1>First</h1>
    // </Flex>

    //     </Flex>
    //     </div>

    <footer id="footer" className="dark">
      <div className="footer-wrap">
        <Row>
          <Col md={6} sm={24} xs={24}>
            <div className="footer-center">
              <div>
                <img src={logo} style={contentStyle} alt="Logo" />
              </div>
            </div>
          </Col>
          <Col md={4} sm={24} xs={24}>
            <div className="footer-center">
            <div>
              <h2 style={fonts}>Important Links</h2>
              </div>
              <span> - </span>
              <div>
                <Link to="/" style={fontsText}>
                  Home
                </Link>
              </div>
              
              <div>
                <Link to="/about" style={fontsText}>
                  About
                </Link>
              </div>
              
              <div>
                <Link to="/contact" style={fontsText}>
                  Contact
                </Link>
              </div>
              
              <div>
                <Link to="/contact" style={fontsText}>
                  Donations
                </Link>
              </div>
            </div>
          </Col>


          <Col md={9} sm={24} xs={24}>
            <div className="footer-center">
              <div>
               <h3 style={fonts}>Bank Details</h3>
              </div>
              <span> - </span>
              <div>
              <h4 style={fontsText}>Bank Name : Wise Payments Limited</h4>
              </div>
             
              <div>
              <h4 style={fontsText}>Account Name : Foundation for Rehabilitation & Education for Special Children Ltd</h4>
              </div>
             
              <div>
              <h4 style={fontsText}>Account Number : 61967513</h4>
              </div>
              <div>
              <h4 style={fontsText}>IBAN : GB29 TRWI 2308 0161 9675 13</h4>
              </div>
            </div>
          </Col>


          <Col md={3} sm={24} xs={24}>
            <div className="footer-center">
            <div>
               <h3 style={fonts}>Social Media</h3>
              </div>
              <span> - </span>
              <div>
              <Link target="_blank " to="/">
                <WhatsAppOutlined  style={{
                    color: "orange",
                     fontSize: '22px'
                  }}/>
                  </Link>
              </div>
              <span> - </span>
              <div>
              <Link target="_blank " to="https://www.facebook.com/people/FRESH-Foundation-for-Rehabilitation-Education-of-Slow-Children/100065244470810/">
                <FacebookOutlined  style={{
                    color: "orange",
                     fontSize: '22px'
                  }}/>
                  </Link>
              </div>
              <span> - </span>
              <div>
              <Link target="_blank " to="/">
                <InstagramFilled
                  style={{
                    color: "orange",
                     fontSize: '22px'
                  }}
                />
                </Link>
              </div>
              <span> - </span>
              <div>
              <Link target="_blank " to="/">
                <XOutlined  style={{
                    color: "orange",
                     fontSize: '22px'
                  }}/>
                  </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
