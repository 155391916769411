import React from 'react';
import { Link } from 'react-router-dom';
import {  PhoneOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
const { Meta } = Card;

const WhatweDo = () => {
  return (
    <Row gutter={16}>
    <Col span={8}>
        <Card
    hoverable
    style={{
      width: '100%',
    }}
    cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
    </Col>
    <Col span={8}>
        <Card
    hoverable
    style={{
      width: '100%',
    }}
    cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
    </Col>
    <Col span={8}>
        <Card
    hoverable
    style={{
      width: '100%',
    }}
    cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
    </Col>
  </Row>
  );
};

export default WhatweDo;
