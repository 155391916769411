import React from 'react';
import { Link } from 'react-router-dom';
import {  PhoneOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons';

const HowWeDo = () => {
  return (
    <div style={{ padding: '2% 10% 10% 2%', border: '1px solid #ccc', borderRadius: '5px', maxWidth: '70%' ,align:'center'}}>
    <h2 style={{ marginBottom:'35px' }}>Contact Information</h2>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <PhoneOutlined style={{ fontSize: '20px', color: 'blue', marginRight: '26px' }} />
      <span>+1 (555) 123-4567</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <MailOutlined style={{ fontSize: '20px', color: 'blue', marginRight: '26px' }} />
      <span>admin@freshs.uk</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <EnvironmentOutlined style={{ fontSize: '20px', color: 'blue', marginRight: '26px' }} />
      <span>123 Main St, Anytown, USA</span>
    </div>
   
  </div>
  );
};

export default HowWeDo;
