import React from 'react';

import { Row, Col, Card, Typography, Image } from 'antd';
import ceo from "../Ashba.jpg";

const { Title, Paragraph ,Text} = Typography;

const About = () => {
  return (
    <div style={{ padding: '50px' }}>
    {/* Organization Introduction */}
    <Title level={2}>About Foundation for Rehabilitation & Education for Special Children (FRESH)</Title>
    {/* <Paragraph>
      Welcome to Foundation for Rehabilitation & Education for Special Children! We are a leading company in [Your Industry] with years of experience 
      in delivering high-quality products and services. Our mission is to provide excellent value to 
      our customers and drive innovation in the market. With a dedicated team and a passion for growth, 
      we have achieved numerous milestones and look forward to many more.
    </Paragraph> */}

    {/* Owner Information */}
    <Row gutter={16} style={{ marginTop: '30px' }}>
      {/* Owner Image */}
      <Col xs={24} sm={12} lg={8}>
        <Card cover={<Image src={ceo} alt="Owner" />} bordered={false}>
          <Title level={4}>Director</Title>
          <Paragraph>
            <Text strong>Ashba Kamran</Text>  is the founder and CEO of Foundation for Rehabilitation & Education for Special Children. With a strong vision and commitment 
            to excellence, <Text strong>Ashba Kamran</Text>  has led the organization to help over the past 25+ years. Their expertise 
            and leadership continue to inspire the entire team and shape the future of the organization.
          </Paragraph>
        </Card>
      </Col>

      {/* Organization Overview Card */}
      <Col xs={24} sm={12} lg={16}>
        <Card title="Our Mission and Vision" bordered={false}>
          <Paragraph>
          FRESH has been changing lives since 2002. Its vision has evolved through different phases.
           The struggle started off with one school having remedial program.Unlike mainstream schools,
            this remedial school was a pilot project. It had no business proposal, no agenda for profits and future plans. 
            Founder and CEO, Ashba Kamran brought this program from US to Pakistan to give a try to educate her son and 
            three other children like him. Problem was not only their learning difficulty but society's failure to 
            understand them and provide them opportunities to flourish.This begining was later coupled by FRESH's 
            job placement program and trainings.One school expanded to three more schools.Then job placement program 
            became nationwide program.In ten years of hard work, FRESH has faced many hard times in convincing people, 
            parents, and companies. As a result it had launched its awareness campaign in 2012 by the name of Paidal Pakistan Association for Difficulties in Learning. 
            Today people know about Paidal, They talk about learning difficulties but unfortunately fear of the social stigma has captivated their minds. 
            FRESH, aims to free all minds of this slavish thought, wake-up everyone about the learning problems and focus towards their solution.
          </Paragraph>
        </Card>
      </Col>
    </Row>
  </div>
  );
};

export default About;
